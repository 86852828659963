
import React, { useState } from "react";
import Message from "../LoadingError/Error";
import { useDispatch, useSelector } from "react-redux";
import Toast from "./../LoadingError/Toast";
import { toast } from "react-toastify";
import Loading from "../LoadingError/Loading";
import { updateUserReferral } from "../../Redux/Actions/ReferralAction";


const Promo = (props) => {

  const toastId = React.useRef(null);

const Toastobjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const dispatch = useDispatch();

const userReferrals = useSelector((state) => state.userUpdateReferral);
const { loading, error, success } = userReferrals;


  const { referrals } = props;

  const [copiedCode, setCopiedCode] = useState(null);
  const [inputValues, setInputValues] = useState({});

  const copyToClipboard = (code) => {
    const tempInput = document.createElement("input");
    tempInput.value = code;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopiedCode(code);
  };

  const handleInputChange = (e, referralId) => {
    setInputValues({ ...inputValues, [referralId]: e.target.value });
  };

  const validateFields = (referralId) => {

    if( !inputValues || Object.keys(inputValues).length == 0 || (inputValues[referralId] == "" || inputValues[referralId].length != 8)) {
     
      return "Invalid Promo Code"
    }
    return null;
  };

  

  const handleSubmit = async (e, referralId) => {
    e.preventDefault();
  
    const validationError = validateFields(referralId);
  
    if (validationError) {
    
      if (!toast.isActive(toastId.current)) {
       
        toastId.current = toast.error(validationError, Toastobjects);
      }
    } else {
      try {
        const referredById = inputValues[referralId]; // Get the specific referredById value
        if (!referredById || referredById.length !== 8) {
          throw new Error("Invalid Promo Code");
        }
  
        const payload = {
          referralId: referralId,
          referredById: referredById 
        };
 
        let result = await dispatch(updateUserReferral(payload));
    

        if (!toast.isActive(toastId.current) && result) {
          toastId.current = toast.success("Promo Code Updated Successfully!", Toastobjects);
         setTimeout(function () { window.location.reload()}, 3000);
        }else if (!toast.isActive(toastId.current) && !result){
          toastId.current = toast.error(error || "Failed to update. Please try again.", Toastobjects);
        }
      } catch (error) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(error || "Failed to update. Please try again.", Toastobjects);
        }
      }


    }
  };



  return (
    <>
    <Toast />
    <div className="d-flex justify-content-center align-items-center flex-column">

      {loading ? (
        <Loading />
      ) : error ? (
        
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <>
          {referrals && referrals.length === 0 ? "" : (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr  style={{
                              fontSize: "15px",
                              height: "2rem", 
                              width: "6rem",
                              alignItems: "center",
                              background: "#C9C9C9",
                              borderColor:"#000000",
                              paddingTop: "25px" ,
                              paddingBottom:"25px"
                            }}>
                    <th>PROMO CODE</th>
                    <th>PRICE</th>
                    <th>REFERRED COUNT</th>
                    <th>REFERRAL STATUS</th>
                    <th>PAY STATUS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {referrals &&
                    referrals.map((referral) => (
                      <tr
                        className={`${
                          referral.referral_count == 2
                            ? "alert-success"
                            : "alert-danger"
                        }`}
                        key={referral._id}
                      >
                        <td style={{
                              paddingTop:"25px",
                              paddingBottom:"25px",
                              
                            }} >
                          <button
                            onClick={() => copyToClipboard(referral.referral_code)}
                            className="btn btn-info"
                            style={{
                              fontSize: "12px",
                              height: "2rem", 
                              width: "6rem",
                              alignItems: "center",
                              background: "#C9C9C9",
                              borderColor:"#000000",
                            }}
                          >
                            {referral.referral_code}
                          </button>
                          {copiedCode === referral.referral_code && (
                            <span style={{ marginLeft: "5px", color: "black", fontSize: "10px" }}>
                              Copied!
                            </span>
                          )}
                        </td>
                        <td style={{
                              paddingTop:"25px",
                              paddingBottom:"25px" }}>{referral.referral_value}</td>
                        <td style={{
                              paddingTop:"25px",
                              paddingBottom:"25px" }}>
                        {referral.referral_count}
                        </td>
                        <td style={{
                              paddingTop:"25px",
                              paddingBottom:"25px" }}>
                        {referral.status}
                        </td>
                        <td style={{
                              paddingTop:"25px",
                              paddingBottom:"25px" }}>{referral.referral_pay_status}</td>
                        <td   style={{
                         
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingTop:"35px",
                              paddingBottom:"35px" 

                        }}>

                          <input
                            type="text"
                            value={inputValues[referral._id] || ""}
                            onChange={(e) => handleInputChange(e, referral._id)}
                            placeholder="Referral Code"
                            style={{ marginRight: "5px" , width: "8rem"}}
                            disabled = {referral.referred_by != null ? true : false}
                          />
                          <button
                            onClick={(e) => handleSubmit(e, referral._id)}
                            className="btn btn-success"
                            style={{
                              fontSize: "12px", 
                          color: "#000000",
                          background: "#969595",
                          borderColor:"#000000",
                          fontStyle: "normal",
                            }}
                            disabled = {referral.referred_by != null ? true : false}
                          >
                            Submit
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
    </>
  );
};

export default Promo;
