import {
  REFERRAL_DETAILS_FAIL,
  REFERRAL_DETAILS_REQUEST,
  REFERRAL_DETAILS_SUCCESS,
  REFERRAL_LIST_MY_FAIL,
  REFERRAL_LIST_MY_REQUEST,
  REFERRAL_LIST_MY_SUCCESS,
  USER_UPDATE_REFERRAL_REQUEST,
  USER_UPDATE_REFERRAL_SUCCESS,
  USER_UPDATE_REFERRAL_FAIL

} from "../Constants/ReferralConstants";
import axios from "axios";
import { logout } from "./userActions";



// REFERRAL DETAILS
export const getReferralDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRAL_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/promos/${id}`, config);
    dispatch({ type: REFERRAL_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: REFERRAL_DETAILS_FAIL,
      payload: message,
    });
  }
};


// USER REFERRALS
export const listMyReferrals = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRAL_LIST_MY_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/promos/`, config);
    dispatch({ type: REFERRAL_LIST_MY_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: REFERRAL_LIST_MY_FAIL,
      payload: message,
    });
  }
};

// Update REFERRAL DETAILS
export const updateUserReferral = (referral) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REFERRAL_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/promos/addPromoCode`, referral, config);
    dispatch({ type: USER_UPDATE_REFERRAL_SUCCESS, payload: data });
    return true;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_REFERRAL_FAIL,
      payload: message,
    });
    return false;
  }
};