import {
    REFERRAL_DETAILS_FAIL,
    REFERRAL_DETAILS_REQUEST,
    REFERRAL_DETAILS_SUCCESS,
    REFERRAL_LIST_MY_FAIL,
    REFERRAL_LIST_MY_REQUEST,
    REFERRAL_LIST_MY_RESET,
    REFERRAL_LIST_MY_SUCCESS,
    USER_UPDATE_REFERRAL_REQUEST,
    USER_UPDATE_REFERRAL_SUCCESS,
    USER_UPDATE_REFERRAL_FAIL
  } from "../Constants/ReferralConstants";
  
  
  // REFERRAL DETAILS
  export const referralDetailsReducer = (
    state = { loadingR: true, referralItems: [] },
    action
  ) => {
    switch (action.type) {
      case REFERRAL_DETAILS_REQUEST:
        return { ...state, loading: true };
      case REFERRAL_DETAILS_SUCCESS:
        return { loadingR: false, referrals: action.payload };
      case REFERRAL_DETAILS_FAIL:
        return { loadingR: false, errorR: action.payload };
      default:
        return state;
    }
  };
  
  // USER REFERRALS
  export const referralListMyReducer = (state = { referrals: [] }, action) => {
    switch (action.type) {
      case REFERRAL_LIST_MY_REQUEST:
        return { loadingR: true };
      case REFERRAL_LIST_MY_SUCCESS:
        return { loadingR: false, referrals: action.payload };
      case REFERRAL_LIST_MY_FAIL:
        return { loadingR: false, errorR: action.payload };
      case REFERRAL_LIST_MY_RESET:
        return { referrals: [] };
      default:
        return state;
    }
  };
  
  // UPDATE Referral
export const userUpdateReferralReducer = (state = {}, action) => {

  switch (action.type) {
    case USER_UPDATE_REFERRAL_REQUEST:
      return { loading: true };
    case USER_UPDATE_REFERRAL_SUCCESS:
      return { loading: false, success: true, referralInfo: action.payload };
    case USER_UPDATE_REFERRAL_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};