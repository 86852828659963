import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import ProfileTabs from "../components/profileComponents/ProfileTabs";
import ReferralTab from "../components/profileComponents/ReferralTab";
import { getUserDetails } from "../Redux/Actions/userActions";
import Orders from "./../components/profileComponents/Orders";
import moment from "moment";
import { listMyOrders } from "../Redux/Actions/OrderActions";
import { listMyReferrals } from "../Redux/Actions/ReferralAction";

const ProfileScreen = () => {
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading, error, orders } = orderListMy;
  const referralListMy = useSelector((state) => state.referralListMy);
  const { loadingR, errorR, referrals } = referralListMy;

  let totalEarnings = 0;
  if (referrals) {
    const twoReferralReferrals = referrals.filter(
      (referral) => referral.referral_count === 2
    );
    totalEarnings = twoReferralReferrals.reduce(
      (total, referral) => total + referral.referral_value,
      0
    );
    totalEarnings *= 1.5;
  }

  let totalPayout = 0;
  if (referrals) {
    const filteredReferrals = referrals.filter(
      (referral) =>
        referral.referral_count === 2 &&
        referral.referral_pay_status === "Success" &&
        referral.referral_pay_processed == true
    );
  
    totalPayout = filteredReferrals.reduce(
      (total, referral) => total + referral.referral_value,
      0
    );
    totalPayout *= 1.5;
  }



  useEffect(() => {
    dispatch(listMyOrders());
    dispatch(getUserDetails("profile"));
    dispatch(listMyReferrals());
  }, [dispatch]);

  return (
    <>
      <Header />
      <div className="container mt-lg-5 mt-3">
        <div className="row align-items-start">
          <div className="col-lg-4 p-0 shadow ">
            <div className="author-card pb-0 pb-md-3">
              <div className="author-card-cover"></div>
              <div className="author-card-profile row">
                <div className="author-card-avatar col-md-5">
                  <img src="./images/user.png" alt="userprofileimage" />
                </div>
                <div className="author-card-details col-md-7">
                  <h5 className="author-card-name mb-2">
                    <strong>{userInfo.name}</strong>
                  </h5>
                  <span className="author-card-position">
                    <>Joined {moment(userInfo.joined).format("LL")}</>
                  </span>
                  <hr />
                </div>

                <div className="author-card-details col-md-7">
                  <div
                    className="total-sections-container"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      className="total-section"
                      style={{ marginBottom: "15px" }}
                    >
                      <div
                        className="total-section-content"
                        style={{
                          backgroundColor: "#f0f0f0",
                          padding: "15px",
                          borderRadius: "15px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h6 className="author-card-name mb-3">
                            <strong>Total Earnings</strong>
                          </h6>
                          <span className="author-card-position">
                            <b>₹</b> <b>{totalEarnings}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="total-section">
                      <div
                        className="total-section-content"
                        style={{
                          backgroundColor: "#f0f0f0",
                          padding: "15px",
                          borderRadius: "15px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <h6 className="author-card-name mb-3">
                            <strong>Total Payout</strong>
                          </h6>
                          <span className="author-card-position">
                            <b>₹</b> <b>{totalPayout}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wizard pt-3 ">
              <div className="d-flex align-items-start">
                <div
                  className="nav align-items-start flex-column col-12 nav-pills me-3 "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    Profile
                  </button>
                  <button
                    className="nav-link d-flex justify-content-between "
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Orders List
                    <span className="badge2">{orders ? orders.length : 0}</span>
                  </button>
                  <button
                    className="nav-link d-flex justify-content-between "
                    id="v-pills-promo-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-promo"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-promo"
                    aria-selected="false"
                  >
                    Promotional Codes
                    <span className="badge2">
                      {referrals ? referrals.length : 0}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* panels */}
          <div
            className="tab-content col-lg-8 pb-5 pt-lg-0 pt-3"
            id="v-pills-tabContent"
          >
            <div
              className="tab-pane fade "
              id="v-pills-promo"
              role="tabpanel"
              aria-labelledby="v-pills-promo-tab"
            >
              <ReferralTab
                referrals={referrals}
                loading={loadingR}
                error={errorR}
              />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <ProfileTabs user={userInfo} loading={loading} error={error} />
            </div>
            <div
              className="tab-pane fade show "
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <Orders orders={orders} loading={loading} error={error} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileScreen;
