import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";

const Orders = (props) => {
  const { loading, error, orders } = props;

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <>
          {orders.length === 0 ? (
            <div className="col-12 alert alert-info text-center mt-3">
              No Orders
              <Link
                className="btn btn-success mx-2 px-3 py-2"
                to="/"
                style={{
                  fontSize: "12px",
                }}
              >
                START SHOPPING
              </Link>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr
                  style={{
                    fontSize: "15px",
                    height: "2rem", 
                    width: "6rem",
                    alignItems: "center",
                    background: "#C9C9C9",
                    borderColor:"#000000",
                    paddingTop: "25px" ,
                    paddingBottom:"25px"
                  }}
                  >
                    <th>ORDER ID</th>
                    <th>DATE</th>
                    <th>TOTAL</th>
                    <th>INVOICE</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr
                      className={`${
                        order.financial_status
                          ? "alert-success"
                          : "alert-danger"
                      }`}
                      key={order._id}
                    >
                      <td>{order.order_number}</td>
                      <td>{moment(order.updated_at).format("MMMM Do YYYY, h:mm:ss a")}</td>
                      <td>₹ {order.total_line_items_price}</td>
                      <td>
                        <a
                          href={order.order_status_url}
                          className="btn btn-primary"
                          style={{
                            fontSize: "12px", 
                        color: "#000000",
                        background: "#C9C9C9",
                        borderColor:"#000000"
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;
