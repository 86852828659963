import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../LoadingError/Error";
import Toast from "./../LoadingError/Toast";
import Loading from "./../LoadingError/Loading";
import { toast } from "react-toastify";
import { updateUserProfile } from "../../Redux/Actions/userActions";

const ProfileTabs = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [upiId, setUpiId] = useState("");
  const [upiMobile, setUpiMobile] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");

  

  const toastId = React.useRef(null);

  const Toastobjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
  };

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;



  useEffect(() => {

    if  (user && Object.keys(user).length != 0 ) {
      setAccountNumber(user.bank_details.account_no || "");
      setBankName(user.bank_details.bank_name || "");
      setIfscCode(user.bank_details.ifsc || "");
      setAccountHolderName(user.bank_details.account_holder_name || "");
      setUpiId(user.bank_details.upi_id || "");
      setUpiMobile(user.bank_details.upi_mobile_number || "");
    }
  }, [dispatch, user]);

  const validateFields = () => {

    if((accountHolderName == user.bank_details.account_holder_name) && (accountNumber == user.bank_details.account_no) && (confirmAccountNumber == "") && (bankName == user.bank_details.bank_name) && (ifscCode == user.bank_details.ifsc) && (upiId == user.bank_details.upi_id) && (upiMobile == user.bank_details.upi_mobile_number)){
      return "Already up to date"
    }

    if (!accountHolderName || !accountNumber || ((accountNumber != user.bank_details.account_no) && !confirmAccountNumber) || !bankName || !ifscCode) {
      return "All * fields are required";
    }
  
    if ((accountNumber != user.bank_details.account_no || confirmAccountNumber != "") && accountNumber !== confirmAccountNumber) {
      return "Account numbers do not match";
    }

    if (!/^\d+$/.test(accountNumber)) {
      return "Account number should contain only numbers";
    }
  
    if (ifscCode.length !== 11) {
      return "IFSC code should be exactly 11 characters";
    }

    if(upiMobile && upiMobile.length !== 10) {
      return "UPI Mobile Number must be 10 digit";
    }

    if (upiMobile && !/^\d+$/.test(upiMobile)) {
      return "UPI Mobile Number should contain only numbers";
    }
  
    return null;
  };

  const submitHandler = async (e, type) => {
    e.preventDefault();

    const validationError = validateFields();

    if (validationError) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(validationError, Toastobjects);
      }
    } else {
      try {
        const payload = {
          id: user._id,
          accountNumber,
          confirmAccountNumber,
          bankName,
          upiId,
          upiMobile,
          ifscCode,
          accountHolderName
        };

        await dispatch(updateUserProfile(payload));

        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(
            type === "profile" ? "Profile Updated" : "Bank Details Updated",
            Toastobjects
          );
        }
      } catch (error) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Failed to update. Please try again.", Toastobjects);
        }
      }
    }
  };

  return (
    <>
      <Toast />
      {error && <Message variant="alert-danger">{error}</Message>}
      {loading && <Loading />}
      <div className="row">
        <div className="col-md-12">
          <ul className="nav nav-tabs mb-3" id="myTabs">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="profile-tab"
                data-bs-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="true"
              >
                Profile
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="bank-details-tab"
                data-bs-toggle="tab"
                href="#bank-details"
                role="tab"
                aria-controls="bank-details"
                aria-selected="false"
              >
                Bank Details
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabsContent">
            <div
              className="tab-pane fade show active"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <form
                className="row form-container"
                onSubmit={(e) => submitHandler(e, "profile")}
              >
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="border p-3">
                      <label htmlFor="account-fn">First Name</label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={user?.first_name || ""}
                        disabled
                      />
                      <label htmlFor="account-ln">Last Name</label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={user?.last_name || ""}
                        disabled
                      />
                      <label htmlFor="account-email">E-mail Address</label>
                      <input
                        className="form-control custom-background"
                        type="email"
                        value={user?.email || ""}
                        disabled
                      />
                      <label htmlFor="account-mobile">Mobile Number</label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={user?.mobile || ""}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="bank-details"
              role="tabpanel"
              aria-labelledby="bank-details-tab"
            >
              <form
                className="row form-container"
                onSubmit={(e) => submitHandler(e, "bank-details")}
              >
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="border p-3">
                      <label htmlFor="account-account">Account Holder Name <span className="mandatory">*</span></label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={accountHolderName}
                        onChange={(e) => setAccountHolderName(e.target.value)}
                      />
                      <label htmlFor="account-account">Account Number <span className="mandatory">*</span></label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                      />
                      <label htmlFor="account-confirm-account">
                        Confirm Account Number
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={confirmAccountNumber}
                        onChange={(e) => setConfirmAccountNumber(e.target.value)}
                      />
                      <label htmlFor="account-bank">Bank Name <span className="mandatory">*</span></label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                      />
                      <label htmlFor="account-ifsc">IFSC Code <span className="mandatory">*</span></label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={ifscCode}
                        onChange={(e) => setIfscCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="border p-3">
                      <label htmlFor="account-ifsc">UPI ID <span className="optional">(Optional)</span></label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={upiId}
                        onChange={(e) => setUpiId(e.target.value)}
                      />
                      <label htmlFor="account-ifsc">UPI Mobile Number <span className="optional">(Optional)</span></label>
                      <input
                        className="form-control custom-background"
                        type="text"
                        value={upiMobile}
                        onChange={(e) => setUpiMobile(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Update Bank Details
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTabs;

