import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userDetailsReducer,
  userLoginReducer,
  userUpdateProfileReducer,
} from "./Reducers/userReducers";

import {
  orderDetailsReducer,
  orderListMyReducer,
} from "./Reducers/OrderReducres";

import {
  referralDetailsReducer,
  referralListMyReducer,
  userUpdateReferralReducer
} from "./Reducers/ReferralReducer";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  orderDetails: orderDetailsReducer,
  orderListMy: orderListMyReducer,
  referralListMy: referralListMyReducer,
  referralDetails: referralDetailsReducer,
  userUpdateReferral: userUpdateReferralReducer
  
});

// login
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromLocalStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
