export const REFERRAL_DETAILS_REQUEST = "REFERRAL_DETAILS_REQUEST";
export const REFERRAL_DETAILS_SUCCESS = "REFERRAL_DETAILS_SUCCESS";
export const REFERRAL_DETAILS_FAIL = "REFERRAL_DETAILS_FAIL";

export const REFERRAL_LIST_MY_REQUEST = "REFERRAL_LIST_MY_REQUEST";
export const REFERRAL_LIST_MY_SUCCESS = "REFERRAL_LIST_MY_SUCCESS";
export const REFERRAL_LIST_MY_FAIL = "REFERRAL_LIST_MY_FAIL";
export const REFERRAL_LIST_MY_RESET = "REFERRAL_LIST_MY_RESET";

// User update Referral constants
export const USER_UPDATE_REFERRAL_REQUEST = "USER_UPDATE_REFERRAL_REQUEST";
export const USER_UPDATE_REFERRAL_SUCCESS = "USER_UPDATE_REFERRAL_SUCCESS";
export const USER_UPDATE_REFERRAL_FAIL = "USER_UPDATE_REFERRAL_FAIL";
